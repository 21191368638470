import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import { AboutPageTemplate } from '../components/AboutPageTemplate'
import { HTMLContent } from '../components/Content'
import Layout from '../components/Layout'

const AboutPage = ({ data }) => {
  const { markdownRemark: page } = data

  return (
    <Layout>
      <AboutPageTemplate
        contentComponent={HTMLContent}
        title={page.frontmatter.title}
        content={page.html}
      />
    </Layout>
  )
}

export default AboutPage

AboutPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export const pageQuery = graphql`
  query AboutPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
      }
    }
  }
`
